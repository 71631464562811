import React, { useContext, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Input, Modal, Popconfirm, Typography } from 'antd';
import { toast, ToastContainer } from "react-toastify";
import BaseContext from "../../Contexts/BaseContext";

const TinyMceEditor = ({ selectedTemplate, setSelectedTemplate, addTemplate, emailBodyHtmlHandler, emailBodyTextHandler }) => {
    const editorRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const { currentUser } = useContext(BaseContext);

    const showModal = () => {
        setIsModalOpen(true);
    };

    function myCustomOnChangeHandler(newValue, editor) {
        emailBodyHtmlHandler(newValue)
        emailBodyTextHandler(editor.getContent({ format: 'text' }))
    }

    const handleOk = async () => {
        const response = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/emailTemplate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ templateName, content: editorRef.current.getContent(), userId: currentUser._id }),
        })

        if (response.ok) {
            toast.success("Template created successfully", { delay: 5000 });
        } else {
            toast.error("Errorl while template creation", { delay: 5000 });
        }

        const responseData = await response.json();
        const result = responseData.result

        addTemplate(result);
        setSelectedTemplate(result)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const templateNameChangeHandler = (e) => {
        setTemplateName(e.target.value);
    }

    return (
        <div className="richText">
            <ToastContainer />
            <Editor
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={selectedTemplate?.content}
                onEditorChange={myCustomOnChangeHandler}
                init={{
                    selector: 'textarea',
                    placeholder: "Please write your email here...",
                    plugins: 'anchor autolink charmap codesample emoticons image link lists searchreplace table visualblocks wordcount',
                    toolbar: 'undo redo link image blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    tinycomments_mode: 'embedded',
                    tinycomments_author: 'Author name',
                    mergetags_list: [
                        { value: 'First.Name', title: 'First Name' },
                        { value: 'Email', title: 'Email' },
                    ],
                    ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.onchange = function () {
                            var file = this.files[0];

                            var reader = new FileReader();
                            reader.onload = function () {
                                var id = 'blobid' + (new Date()).getTime();
                                var blobCache = editorRef.current.editorUpload.blobCache;
                                var base64 = reader.result.split(',')[1];
                                var blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);
                                cb(blobInfo.blobUri(), { title: file.name });
                            };
                            reader.readAsDataURL(file);
                        };

                        input.click();
                    },
                }}

            />
            <>
                <Button type="primary" onClick={showModal}>
                    Save as new template
                </Button>
                <Modal title="New Email Template" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="Save Template">
                    <Typography.Text>Email Template Name</Typography.Text>
                    <Input placeholder="Email Template name..." value={templateName} onChange={templateNameChangeHandler} />
                </Modal>
            </>
        </div>
    );
};

export default TinyMceEditor;
