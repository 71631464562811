import React from 'react';
import { BiLike } from 'react-icons/bi';
import { FaEquals } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import '../../Components/default.css';
import '../../Components/style.css';


const Navbar = () => {
    return (
        <div className='nav-green'>
            <nav className="navbar navbar-expand-lg container ">
                <div className="container-fluid">
                    <NavLink className="navbar-brand" to="/">
                        <img className='logo-img' src="https://i.ibb.co/JF6h81q/Light-Logo.png" alt="" />
                    </NavLink>

                    <div className="">
                        <ul className="navbar-nav navTwo-nav ">
                            <li className="nav-item ">
                                <BiLike className='svg-icon' />
                            </li>
                            <li className="nav-item active">
                                <FaEquals className='svg-icon' />
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link main-btn btn-upgrade" href="#">
                                    Upgrade To Pro
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        </div>
        // <div className='nav-green'>
        //     <nav className="navbar navbar-expand-lg container">
        //         <div className="container-fluid">
        //             <NavLink to="/"><img className='logo-img' src="https://i.ibb.co/JF6h81q/Light-Logo.png" alt="" /></NavLink>
        //             <div className="header-menu" >
        //                 <ul className="navThree  me-auto pd">
        //                     <>
        //                         <li className="nav-item ">
        //                             <BiLike />
        //                         </li>
        //                         <li className="nav-item active">
        //                             <FaEquals />
        //                         </li>
        //                         <li className="nav-item main-btn upgrade-btn">
        //                             <a className="nav-link" href="#">
        //                                 Upgrade To Pro
        //                             </a>
        //                         </li>

        //                     </>

        //                 </ul>
        //             </div>
        //         </div>
        //     </nav>
        // </div>
    );
};

export default Navbar;