import React from 'react';
import '../../Components/default.css';
import '../../Components/style.css';
import Header from '../Header/Header';
import '../responsive.css';

const About = () => {
    return (
        <>
            <Header />
            <div className="about mt-100">
                <div className="bottom-title">
                    <h2>Know About Birthship</h2>
                </div>
                <div className="mission-area container">
                    <h2>Mission Statement:</h2>
                    <p>To provide a platform where customers can send free emails to their target audience's public email
                        addresses on a daily basis while supporting the overall increase of revenue and customer appeal to
                        their business.</p>
                </div>
                <div className="vision-area container">
                    <h2>Vision Statement:</h2>
                    <p> Utilizing customer data to provide free email and affordable premium marketing services to numerous
                        businesses that are trying to diligently obtain high-level visibility amongst their ideal customer
                        base.</p>
                </div>
            </div>
        </>
    );
};

export default About;