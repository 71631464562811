import React, { useRef, useState, useEffect, useContext } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { Button, DatePicker, Modal, Typography } from "antd";
import { confirmAlert } from "react-confirm-alert";

import useAuth from "../../Hooks/useAuth";
import SelectCity from "../SignUp/SelectCity";
import TinyMceEditor from "./TinyMceEditor";
import moment from 'moment';
import BaseContext from "../../Contexts/BaseContext";

import "../responsive.css";
import "../../Components/default.css";
import "../../Components/style.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const Builder = () => {
    const { user } = useAuth();
    const { currentUser } = useContext(BaseContext);

    const composeAlertMessages = {
        State: 'An email will be sent to all registered users in selected state',
        City: 'An email will be sent to all registered users in selected city',
        ZipCode: 'An email will be sent to all registered users in selected zip code',
        BatchEmail: 'An email will be sent to the entered email addresses'
    }

    const editor = useRef(null);
    const [emailBodyHtml, setEmailBodyHtml] = useState("");
    const [emailBodyText, setEmailBodyText] = useState("");
    const [cc, setCc] = useState(false);
    const [bcc, setBcc] = useState(false);
    const [subject, setSubject] = useState("");
    const [template, setTemplate] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("")
    const [zipCode, setZipCode] = useState("");
    const [composeByState, setComposeByState] = useState(true);
    const [composeByCity, setComposeByCity] = useState(false);
    const [composeByZipCode, setComposeByZipCode] = useState(false);
    const [composeByBatchEmail, setComposeByBatchEmail] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [scheduleTime, setScheduleTime] = useState()
    const [stats, setStats] = useState({ receivedEmails: 0, deliveredEmails: 0 })
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [apiResponseMessage, setApiResponseMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [emailBody, setEmailBody] = useState("");
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [emailTo, setEmailTo] = useState("");
    const [emailCC, setEmailCC] = useState("");
    const [emailBCC, setEmailBCC] = useState("");
    const [alertText, setAlertText] = useState("State")

    const scheduleTimeHandler = (value, dateString) => {
        setScheduleTime(moment(dateString).unix().valueOf())
    };

    const confirmAction = () => {
        confirmAlert({
            title: "Send Email Blast",
            message: composeAlertMessages[alertText],
            buttons: [
                {
                    label: "Yes",
                    onClick: handleEmail
                },
                {
                    label: "No",
                    onClick: handleCancel
                }
            ]
        });
    };

    const composeHandler = (e) => {
        setEmailTo("")
        setEmailCC("")
        setEmailBCC("")
        setCity("")
        setState("")
        setZipCode("")

        const composeBy = e.target.value;

        setComposeByState(composeBy == 'State')
        setComposeByCity(composeBy == 'City')
        setComposeByZipCode(composeBy == 'ZipCode')
        setComposeByBatchEmail(composeBy == 'BatchEmail')

        setAlertText(composeBy)
    }

    const handleOk = async () => {
        setIsModalOpen(false);

        sendEmail()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const emailToHandler = (event) => {
        setEmailTo(event.target.value.split(',').map(mail => mail.trim()));
    };

    const emailCCHandler = (event) => {
        setEmailCC(event.target.value);
    };
    const emailBCCHandler = (event) => {
        setEmailBCC(event.target.value);
    };

    const emailBodyHtmlHandler = (body) => {
        setEmailBodyHtml(body)
    };

    const emailBodyTextHandler = (body) => {
        setEmailBodyText(body)
    };

    const subjectHandler = (event) => {
        setSubject(event.target.value)
    };

    const handleEmail = () => {
        sendEmail()
    };

    useEffect(() => {
        if (currentUser._id) {
            console.log(currentUser._id)

            fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/user/${currentUser._id}/emailTemplates`).then((res) => res.json())
                .then((data) => {
                    const result = data.result

                    setTemplates(result)
                })
        }
    }, [currentUser]);

    const addTemplate = (newTemplate) => {
        setTemplates([...templates, newTemplate]);
    }

    useEffect(() => {
        setSelectedTemplate(templates.find(temp => temp._id == template) || "")
    }, [template])

    const sendEmail = () => {
        setIsSuccess(false);
        setIsLoading(true);

        const email = JSON.stringify({
            user: user.email || "",
            subject: subject,
            state: state,
            city: city,
            zipCode: zipCode,
            emailBodyText: emailBodyText,
            emailBodyHtml: emailBodyHtml,
            email: emailTo,
            cc: [emailCC],
            bcc: [emailBCC],
            sendAt: scheduleTime
        });

        fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/email/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: email,
        }).then(async (res) => {
            let response = await res.json();

            if (response) {
                setState("");
                setCity("");
                setScheduleTime(null);
                document.getElementById("cars2").selectedIndex = 0;
                setReRender(!reRender);
                setIsLoading(false);
            }

            if (response.errors) {
                setIsError(true);
                setIsLoading(false);
                setApiResponseMessage(response.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 5000);
            } else {
                setIsSuccess(true);
                setIsLoading(false);
                setApiResponseMessage('Thank you for sending email.')
                setTimeout(() => {
                    setIsSuccess(false);
                }, 5000);
            }
        });
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/email/stats`).then((res) => res.json())
            .then((data) => {
                const result = data.result

                setStats(result)
            })
    }, [])

    return (
        <div>
            {/* top nav  */}
            <div className="manu">
                <NavLink to="/">
                    <IoIosArrowBack className="fas fa-angle-left me-3 e-title" />
                    <span className="sale">Black friday sale </span>
                </NavLink>
            </div>
            <hr />
            {/*     subnav    */}

            <div className="subnav">
                <div className="black-logo px-4">
                    <NavLink to="/">
                        <img src="https://i.ibb.co/VmWGkmz/Dark.png" alt="" />
                    </NavLink>
                </div>
                <div className="build-logo px-4">
                    <div className="inbox-icon">
                    </div>
                    <div className="pro-icon">
                    </div>
                </div>
            </div>

            <hr />
            {/* 333  */}
            <main className="container-fluid tam-section">
                <div className="main-section row">
                    <div className=" col-lg-3 col-md-3 col-sm-12 padding-none">
                        <div className="container-fluid px-4">
                            <div>
                                <p
                                    className="em-setting"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                >
                                    Email Settings
                                </p>
                                {/* Modal */}
                                <div
                                    className="modal fade"
                                    id="exampleModal"
                                    tabIndex={-1}
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                    Please Login
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                />
                                            </div>
                                            <div className="modal-body">
                                                <form>
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="exampleInputEmail1"
                                                            className="form-label"
                                                        >
                                                            Email address
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                        />
                                                        <div id="emailHelp" className="form-text">
                                                            We'll never share your email with anyone else.
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="exampleInputPassword1"
                                                            className="form-label"
                                                        >
                                                            Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id="exampleInputPassword1"
                                                        />
                                                    </div>
                                                    <div className="mb-3 form-check">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="exampleCheck1"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleCheck1"
                                                        >
                                                            Check me out
                                                        </label>
                                                    </div>
                                                    <button type="submit" className="build-btn3">
                                                        Submit
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="build-btn3"
                                                    data-bs-dismiss="modal"
                                                >
                                                    Close
                                                </button>
                                                <button type="button" className="build-btn3">
                                                    Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* left  */}
                            <div className="tamp">
                                <h6>Select Template</h6>
                            </div>
                            <div className="tamp">
                                <select
                                    name="cars"
                                    id="cars"
                                    className="templateClass"
                                    onChange={(e) => setTemplate(e.target.value)}
                                >
                                    <option value="" disabled selected>Selete Template</option>
                                    {templates?.map(temp => <option key={temp._id} value={temp._id}>{temp.templateName}</option>)}
                                </select>
                            </div>

                            <div className="tamp">
                                <h6>Select State</h6>
                            </div>
                            <div className="tamp">
                                <select
                                    name="cars"
                                    id="cars2"
                                    className="stateClass"
                                    disabled={!composeByState}
                                    onChange={(e) => setState(e.target.value)}
                                >
                                    <option value="">State</option>
                                    <option value="Alabama">Alabama</option>
                                    <option value="Alaska">Alaska</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Arizona">Arizona</option>
                                    <option value="Arkansas">Arkansas</option>
                                    <option value="Baker Island">Baker Island</option>
                                    <option value="California">California</option>
                                    <option value="Colorado">Colorado</option>
                                    <option value="Connecticut">Connecticut</option>
                                    <option value="Delaware">Delaware</option>
                                    <option value="District of Columbia">
                                        District of Columbia
                                    </option>
                                    <option value="Florida">Florida</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Howland Island">Howland Island</option>
                                    <option value="Idaho">Idaho</option>
                                    <option value="Illinois">Illinois</option>
                                    <option value="Indiana">Indiana</option>
                                    <option value="Iowa">Iowa</option>
                                    <option value="Jarvis Island">Jarvis Island</option>
                                    <option value="Johnston Atoll">Johnston Atoll</option>
                                    <option value="Kansas">Kansas</option>
                                    <option value="Kentucky">Kentucky</option>
                                    <option value="Kingman Reef">Kingman Reef</option>
                                    <option value="Louisiana">Louisiana</option>
                                    <option value="Maine">Maine</option>
                                    <option value="Maryland">Maryland</option>
                                    <option value="Massachusetts">Massachusetts</option>
                                    <option value="Michigan">Michigan</option>
                                    <option value="Midway Atoll">Midway Atoll</option>
                                    <option value="Minnesota">Minnesota</option>
                                    <option value="Mississippi">Mississippi</option>
                                    <option value="Missouri">Missouri</option>
                                    <option value="Montana">Montana</option>
                                    <option value="Navassa Island">Navassa Island</option>
                                    <option value="Nebraska">Nebraska</option>
                                    <option value="Nevada">Nevada</option>
                                    <option value="New Hampshire">New Hampshire</option>
                                    <option value="New Jersey">New Jersey</option>
                                    <option value="New Mexico">New Mexico</option>
                                    <option value="New York">New York</option>
                                    <option value="North Carolina">North Carolina</option>
                                    <option value="North Dakota">North Dakota</option>
                                    <option value="Northern Mariana Islands">
                                        Northern Mariana Islands
                                    </option>
                                    <option value="Ohio">Ohio</option>
                                    <option value="Oklahoma">Oklahoma</option>
                                    <option value="Oregon">Oregon</option>
                                    <option value="Palmyra Atoll">Palmyra Atoll</option>
                                    <option value="Pennsylvania">Pennsylvania</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Rhode Island">Rhode Island</option>
                                    <option value="South Carolina">South Carolina</option>
                                    <option value="South Dakota">South Dakota</option>
                                    <option value="Tennessee">Tennessee</option>
                                    <option value="Texas">Texas</option>
                                    <option value="United States Minor Outlying Islands">
                                        United States Minor Outlying Islands
                                    </option>
                                    <option value="United States Virgin Islands">
                                        United States Virgin Islands
                                    </option>
                                    <option value="Utah">Utah</option>
                                    <option value="Vermont">Vermont</option>
                                    <option value="Virginia">Virginia</option>
                                    <option value="Wake Island">Wake Island</option>
                                    <option value="Washington">Washington</option>
                                    <option value="West Virginia">West Virginia</option>
                                    <option value="Wisconsin">Wisconsin</option>
                                    <option value="Wyoming">Wyoming</option>
                                </select>
                            </div>
                            <div className="tamp">
                                <h6>Enter Zip Code</h6>
                                <div className="input-group mb-3">
                                    <input
                                        id="zip-code"
                                        type="text"
                                        className="input-bg px-3"
                                        placeholder="Zip"
                                        required
                                        maxLength="5"
                                        disabled={!composeByZipCode}
                                        onChange={(e) => setZipCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="tamp">
                                <h6>Select City</h6>
                                <SelectCity setCity={setCity} isDisabled={!composeByCity} />
                            </div>

                            <div className="fl-lft">
                                <a href="#">
                                    <button className="build-btn2" onClick={() => setIsModalOpen(true)}>Schedule Email</button>
                                </a>
                                <a href="#">
                                    {" "}
                                    <button className="build-btn1">Save Changes</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 padding-none d-block">
                        {/* Success Alert */}
                        <div
                            id="success-alert"
                            className={`alert ${isError ? 'alert-danger' : 'alert-success'} mt-3 ${!isSuccess && !isError && "d-none"}`}
                        >
                            {apiResponseMessage}
                        </div>
                        <div className="mid-build">
                            <div className="mid-body-main">
                                {composeByBatchEmail && <div style={{ position: "relative" }} className="mb-3">
                                    <input id="" type="text" placeholder="To" value={emailTo} onChange={emailToHandler} />
                                    <div
                                        style={{
                                            display: "flex",
                                            position: "absolute",
                                            right: "0",
                                            top: "0",
                                        }}
                                    >
                                        <span
                                            style={{
                                                display: `${!cc ? "block" : "none"}`,
                                                cursor: "pointer",
                                            }}
                                            onClick={() => setCc(true)}
                                        >
                                            CC /{" "}
                                        </span>
                                        <span
                                            style={{
                                                display: `${!bcc ? "block" : "none"}`,
                                                cursor: "pointer",
                                            }}
                                            onClick={() => setBcc(true)}
                                        >
                                            {" "}
                                            BCC
                                        </span>
                                    </div>
                                    {cc && <input id="" type="text" value={emailCC} placeholder="CC: " onChange={emailCCHandler} disabled={!composeByBatchEmail} />}
                                    {bcc && <input id="" type="text" value={emailBCC} placeholder="BCC: " onChange={emailBCCHandler} disabled={!composeByBatchEmail} />}
                                </div>}
                                <div className="mb-3">
                                    <input id="" type="text" placeholder="Subject" onChange={subjectHandler} />
                                </div>
                                <div className="mb-3">
                                    <TinyMceEditor
                                        selectedTemplate={selectedTemplate}
                                        setSelectedTemplate={setSelectedTemplate}
                                        addTemplate={addTemplate}
                                        emailBodyHtmlHandler={emailBodyHtmlHandler}
                                        emailBodyTextHandler={emailBodyTextHandler}
                                    />
                                </div>


                                <div className="mid-footer mid-fl">
                                    <div className="email-setting">
                                        <Button
                                            id="sendEmailBtn"
                                            className="build-btn3-snd"
                                            onClick={confirmAction}
                                            disabled={emailBodyText.length == 0}
                                        >
                                            {isLoading ? "Loading..." : "Send"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="left col-lg-3 col-md-3 col-sm-12 padding-none">
                        <div className="px-4 mid-rt">
                            <div className="tamp">
                                <h6>Compose:</h6>
                            </div>
                            <div className="tamp">
                                <select
                                    name="car"
                                    id="cars"
                                    className="selectBy"
                                    onChange={composeHandler}
                                >
                                    <option value="State">Send email via state</option>
                                    <option value="City">Send email via City</option>
                                    <option value="ZipCode">Send email via Zip code</option>
                                    <option value="BatchEmail">Send email via Batch email</option>
                                </select>
                            </div>

                            <div className="">
                                <div className="inbox-main">
                                    <span>{stats.receivedEmails}</span>
                                    <div className="inbox">
                                        <p>Inbox</p>
                                    </div>
                                </div>
                                <div className="inbox-main">
                                    <span>{stats.deliveredEmails}</span>
                                    <div className="inbox">
                                        <p>Sent</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Modal title="Schedule Email" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="Schedule">
                <Typography.Text>Select Schedule Time</Typography.Text>
                <DatePicker showTime onChange={scheduleTimeHandler} />
            </Modal>
        </div>
    );
};

export default Builder;
