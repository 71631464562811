import React, { useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import "../../Components/default.css";
import "../../Components/style.css";
import useAuth from "../../Hooks/useAuth";
import NavThree from "../Navbar/NavThree/NavThree";
import "../responsive.css";
import PhoneSignIn from "./PhoneSignIn";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [passChangeEmail, setPassChangeEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openMobileSignUp, setOPenMobileSignUp] = useState(false);

  let navigate = useNavigate();

  const {
    loginWithEmail,
    passwordReset,
    loginWithGoogle,
    loginWithFacebook,
    loginWithTwitter,
    setUser,
    setErrorMessage,
    setIsLoading,
    isLoading,
    errorMessage,
    logInWithPhoneNumber,
  } = useAuth();

  const handleLoginWithPhone = async () => {
    logInWithPhoneNumber("01785524435").then((result) => {
      console.log("result==>", result);
    });
  };

  const handleLogin = () => {
    loginWithEmail(email, pass)
      .then((userCredential) => {
        const user = userCredential.user;
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        setErrorMessage("");
        return navigate("/builder");
      })
      .catch((error) => {
        setErrorMessage("Something went wrong");
      })
      .finally(() => setIsLoading(false));
  };

  const handleGoogleLogin = () => {
    loginWithGoogle()
      .then((result) => {
        setUser(result.user);
        localStorage.setItem("user", JSON.stringify(result.user));
        setErrorMessage("");

        const user = {
          email: result.user.email,
          displayName: result.user.displayName,
        };

        fetch(
          `${process.env.REACT_APP_SERVER_ADDRESS}/user/${result.user.email}`
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.result) {
              fetch(
                `${process.env.REACT_APP_SERVER_ADDRESS}/user/${data.result._id}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(user),
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  if (data?.message === "Success") {
                    return navigate("/builder");
                  }
                })
                .catch((error) => {
                  setErrorMessage("Something went wrong");
                })
                .finally(() => {
                  setIsLoading(false);
                });
            } else {
              fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/user/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(user),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data?.message === "Success") {
                    return navigate("/builder");
                  }
                })
                .catch((error) => {
                  setErrorMessage("Something went wrong");
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          });
      })
      .catch((error) => {
        setErrorMessage("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFacebookLogin = () => {
    loginWithFacebook()
      .then((result) => {
        setUser(result.user);
        localStorage.setItem("user", JSON.stringify(result.user));
        setErrorMessage("");

        const user = {
          email: result.user.email,
          displayName: result.user.displayName,
        };

        fetch(
          `${process.env.REACT_APP_SERVER_ADDRESS}/user/${result.user.email}`
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.result) {
              fetch(
                `${process.env.REACT_APP_SERVER_ADDRESS}/user/${data.result._id}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(user),
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  if (data?.message === "Success") {
                    return navigate("/builder");
                  }
                })
                .catch((error) => {
                  setErrorMessage("Something went wrong");
                })
                .finally(() => {
                  setIsLoading(false);
                });
            } else {
              fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/user/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(user),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data?.message === "Success") {
                    return navigate("/builder");
                  }
                })
                .catch((error) => {
                  setErrorMessage("Something went wrong");
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          });
      })
      .catch((error) => {
        setErrorMessage("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTwitterLogin = () => {
    loginWithTwitter()
      .then((result) => {
        setUser(result.user);
        localStorage.setItem("user", JSON.stringify(result.user));
        setErrorMessage("");

        const user = {
          email: result.user.email,
          displayName: result.user.displayName,
        };

        fetch(
          `${process.env.REACT_APP_SERVER_ADDRESS}/user/${result.user.email}`
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.result) {
              fetch(
                `${process.env.REACT_APP_SERVER_ADDRESS}/user/${data.result._id}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(user),
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  if (data?.message === "Success") {
                    return navigate("/builder");
                  }
                })
                .catch((error) => {
                  setErrorMessage("Something went wrong");
                })
                .finally(() => {
                  setIsLoading(false);
                });
            } else {
              fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/user/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(user),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data?.message === "Success") {
                    return navigate("/builder");
                  }
                })
                .catch((error) => {
                  setErrorMessage("Something went wrong");
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          });
      })
      .catch((error) => {
        setErrorMessage("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    passwordReset(passChangeEmail)
      .then(() => {
        setIsSuccess(true);
        setErrorMessage("");
      })
      .catch((error) => {
        setErrorMessage("Something went wrong");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <>
        <NavThree></NavThree>
        {/* login-form start */}
        <section className="signin-form container py-4 mt-70">
          <div>
            {/* Error */}
            <div
              className={`alert alert-danger w-50 mx-auto text-center ${
                !errorMessage && "d-none"
              }`}
              id="signin-error"
            >
              {errorMessage}
            </div>
            {/* login-header */}
            <div className="signin-header">
              <h2 className="heading">Sign in</h2>
              <p className="paragraph">We're glad to see you back </p>
              <div className="underline" />
            </div>
            <div className="signin-input">
              <div className="sign-in-op">
                <p>Email or username</p>
                <span onClick={() => setOPenMobileSignUp(true)}>
                  Login with Phone
                </span>
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  id="email"
                  className="input-bg px-3"
                  placeholder="Username or Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="input-bg px-3"
                  placeholder="Password"
                  id="pass"
                  autoComplete="off"
                  onChange={(e) => setPass(e.target.value)}
                  value={pass}
                  required
                />
              </div>
              <div>
                <button
                  onClick={handleLogin}
                  className="main-btn btn-lightGreen w-100"
                  type="submit"
                  id="login-btn"
                >
                  {isLoading ? "Loading..." : "Sign in"}
                </button>
              </div>
              <div className="signin-footer">
                <span className="reset">
                  Forgot your password?{" "}
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Reset it here
                  </a>
                  {/* Modal */}
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Change Password
                          </h5>
                          <button
                            type="button"
                            className="btn-close "
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body">
                          <div
                            className={`alert alert-success ${
                              !isSuccess && "d-none"
                            }`}
                            id="reset-success"
                          >
                            Password Reset Email Send Successfully
                          </div>
                          <div
                            className={`alert alert-danger ${
                              !errorMessage && "d-none"
                            }`}
                            id="reset-fail"
                          >
                            Something Went Wrong Try again
                          </div>
                          <form id="rest-form" onSubmit={handleChangePassword}>
                            <div className="mb-3 modal-input">
                              <input
                                type="email"
                                required=""
                                id="resetEmail"
                                placeholder="Email"
                                value={passChangeEmail}
                                onChange={(e) =>
                                  setPassChangeEmail(e.target.value)
                                }
                              />
                            </div>
                            <a href="#">
                              {" "}
                              <button type="submit" className="build-btn3">
                                {isLoading ? "Loading..." : "Change Password"}
                              </button>
                            </a>
                          </form>
                        </div>
                        <div className="modal-footer"></div>
                      </div>
                    </div>
                  </div>
                </span>
                <p className="instead">
                  Don't have an account?
                  <NavLink to="/signup">Sign up instead</NavLink>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* login-form end */}
      </>
      <PhoneSignIn isOpen={openMobileSignUp} setVisible={setOPenMobileSignUp} />
    </div>
  );
};

export default SignIn;
