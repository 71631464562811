import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  getIdToken,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
  updateProfile,
} from "firebase/auth";
import { useEffect, useState } from "react";
import initializeFirebase from "../Firebase/firebase.init";

// Initialize Firebase App
initializeFirebase();

const useFirebase = () => {
  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [idToken, setIdToken] = useState("");
  const auth = getAuth();

  const loginWithGoogle = () => {
    setIsLoading(true);
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const loginWithFacebook = () => {
    setIsLoading(true);
    const provider = new FacebookAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const loginWithTwitter = () => {
    setIsLoading(true);
    const provider = new TwitterAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const loginWithEmail = (email, pass) => {
    setIsLoading(true);
    return signInWithEmailAndPassword(auth, email, pass);
  };

  const logInWithPhoneNumber = (phoneNumber) => {};

  const passwordReset = (passChangeEmail) => {
    return sendPasswordResetEmail(auth, passChangeEmail);
  };

  const signUpWithEmail = (user) => {
    setIsLoading(true);
    return createUserWithEmailAndPassword(auth, user.email, user.password);
  };

  // Observer User State
  useEffect(() => {
    const unsubscribed = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        getIdToken(user).then((idToken) => {
          setIdToken(idToken);
        });
      } else {
        setUser({});
      }

      setIsLoading(false);
    });

    return () => unsubscribed;
  }, []);

  const logout = () => {
    setIsLoading(true);
    return signOut(auth);
  };

  // Check User Role
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/user/${user.email}`)
      .then((res) => res.json())
      .then((data) => setIsAdmin(data));
  }, [user.email]);

  return {
    user,
    setUser,
    loginWithGoogle,
    loginWithFacebook,
    loginWithTwitter,
    loginWithEmail,
    logInWithPhoneNumber,
    signUpWithEmail,
    logout,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    idToken,
    setIdToken,
    isAdmin,
    setIsAdmin,
    auth,
    updateProfile,
    passwordReset,
  };
};

export default useFirebase;
