import React from 'react';
import '../../Components/default.css';
import '../../Components/style.css';
import '../responsive.css';

const Campaigns = () => {
    return (
        <div>
            <div className="container mb-50">
                <div className="section1">
                    <div className="camp-top">
                        <div className="camp-text">
                            <h1 className="mail mt-3">Campaigns</h1>
                            <p className="mailP">
                                Create new email campaigns, track metrics and performance.
                            </p>
                            <button className="main-btn btn-green">Create Campaigns</button>
                        </div>
                        <div className="camp-btn">
                            <button className="main-btn btn-border">Search Campaigns</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="email-area ShadowBG container">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Open Rate</th>
                            <th>
                                Click Rate
                                <i className="fas fa-caret-down" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table_row">
                            <td data-label="Name">
                                <h5>Black Friday Email</h5>
                                <p>Sent on Nov 26, 2021 at 12:39 pm</p>
                                <div className="camp-button">
                                    <button className="camp-box-btn">New York</button>
                                    <button className="camp-box-btn">Dallas</button>
                                </div>
                            </td>
                            <td data-label="Created">
                                <h6>30%</h6>
                                <p>345 recipients</p>
                            </td>
                            <td data-label="Updated">
                                <h6>3.6%</h6>
                                <p>42 recipients</p>
                            </td>
                        </tr>
                        <tr className="table_row">
                            <td data-label="Name">
                                <h5>Cyber Monday Email</h5>
                                <p>Sent on Nov 29, 2021 at 12:00 pm</p>
                                <div className="camp-button">
                                    <button className="camp-box-btn">New York</button>
                                    <button className="camp-box-btn">Dallas</button>
                                </div>
                            </td>
                            <td data-label="Created">
                                <h6> 25%</h6>
                                <p>301 recipients</p>
                            </td>
                            <td data-label="Updated">
                                <h6> 3.2%</h6>
                                <p>40 recipients</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


        </div>
    );
};

export default Campaigns;