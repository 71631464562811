import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../Components/default.css';
import '../../Components/style.css';
import Header from '../Header/Header';
import '../responsive.css';
const Index = () => {
    return (
        <>
            <Header />
            <div className="home-banner-area">
                <div className="container">
                    {/* <home-banner  */}
                    <div className="home-banner">
                        <div className="home-banner-content">
                            <h2>Email Blast Your Business <br />
                                To Unlimited Success </h2>
                            <p>This FREE email blasting service is exactly what you’ve been desiring to get your startup business off the ground! Even if you’re the owner of a more mature business, email blasting is a great way to allow you to stay relevant within your chosen industry!
                            </p>
                            <br />
                            <NavLink to="/builder"><button className="main-btn btn-green">Send Free Email Blast Now</button></NavLink>
                        </div>
                        <div className="home-banner-img">
                            <img src="https://i.ibb.co/xgRr3qP/banner.png" alt="" />
                        </div>
                    </div>

                </div>
                {/* longer-area */}
                <div className="longer-area">
                    <div className="container">
                        <div className="longer-content">
                            <h2> The Longer you wait, <br />
                                the more money you<a href="#"> don't make!</a></h2>
                            <NavLink to="/builder"><button className="main-btn btn-white">Start making money</button></NavLink>
                        </div>
                    </div>
                </div>
                {/* boost area  */}
                <div className="bost-area container">
                    <div className="boost">
                        <div className="boost-img">
                            <img src="https://i.ibb.co/zhSSSVd/boost.png" alt="" />
                        </div>
                        <div className="boost-content">
                            <h2>Boost the Reach Of Your Business With Confidence and Style</h2>
                            <p>  Have you been struggling to find new marketing strategies for your business?

                                <br />
                                <br />
                                Fortunately, our email blasting service provides you with the perfect opportunity to drive exponentially more traffic to your landing pages. With this service, you have direct access to high-level email software that supports the delivery of one email blast every single day!

                            </p>
                            <NavLink to="/builder"><button className="main-btn btn-green">Send Free Email Blast Now</button></NavLink>
                        </div>
                    </div>
                </div>
                {/* how-area   */}
                <div className="how-area container">
                    <div className="how-content">
                        <h2>How determined are you to scale your business to the top?</h2>
                        <p>Check out our additional pricing plans to send multiple emails a day to individuals you want to transform from prospective leads to loyal customers! </p>
                        <NavLink to="/signup"> <button className="main-btn btn-green">Signup Now</button></NavLink>
                    </div>
                </div>
                {/* audience-area */}
                <div className="audience-area container">
                    <div className="audience">
                        <div className="audience-content">
                            <h2>Target Audience Prospecting Made Simple</h2>
                            <p>Do you have a new feature or limited-times offer you're trying to promote? </p>

                            <p> Instant of wasting time by trying to reach customers Individually, send a quick email blast
                                to inform everyone on
                                yore prospecting list!</p>

                            <p>Gain more traction for your business by sharing exclusive offers with your target
                                our difference! There, once you have
                                them hooked, continue sending email blast at least once a day to support strong scalability
                                for your business. </p>
                        </div>
                    </div>
                </div>
                {/* unlimited-area */}
                <div className="unlimited-area container">
                    <div className="unlimited">
                        <div className="unlimited-content">
                            <h2>Unlimited Prospects <br />
                                For FREE <br />
                            </h2>
                            <p>Our email blasting service is not like any others you’ll find on the market today!
                                Don’t worry about the number of people you add to your list. Our FREE plan covers an unlimited amount of prospects.
                                <br />
                                <br />
                                If you just send one email a day to hundreds of our subscribers, this service is of no cost to you!

                            </p>
                        </div>
                        <div className="unlimited-img">
                            <img src="https://i.ibb.co/fx8Q24D/unlimited.png" alt="" />

                        </div>
                    </div>
                </div>
                {/* about area */}
                <div className="about mt-100">
                    <div className="bottom-title">
                        <h2>Know About Birthship</h2>
                    </div>
                    <div className="mission-area container">
                        <h2>Mission Statement:</h2>
                        <p>To provide a platform where customers can send free emails to their target audience's public email
                            addresses on a daily basis while supporting the overall increase of revenue and customer appeal to
                            their business.</p>
                    </div>
                    <div className="vision-area container">
                        <h2>Vision Statement:</h2>
                        <p> Utilizing customer data to provide free email and affordable premium marketing services to numerous
                            businesses that are trying to diligently obtain high-level visibility amongst their ideal customer
                            base.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
