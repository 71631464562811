import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../Components/default.css";
import "../../Components/style.css";
import useAuth from "../../Hooks/useAuth";
import "./Header.css";
function Header() {
    let navigate = useNavigate();
    const { user, logout, setUser, setErrorMessage, setIsLoading } = useAuth();

    const handleLogout = () => {
        logout()
            .then(() => {
                setUser({});
                localStorage.removeItem("user");
                setErrorMessage("");
                return navigate("/login");
            })
            .catch((error) => {
                setErrorMessage("Something went wrong");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="container">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <NavLink className="navbar-brand" to="/">
                        <img
                            className="logo-img"
                            src="https://i.ibb.co/VmWGkmz/Dark.png"
                            alt=""
                        />
                    </NavLink>
                    <button
                        className="navbar-toggler collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="toggler-icon top-bar" />
                        <span className="toggler-icon middle-bar" />
                        <span className="toggler-icon bottom-bar" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 navbar-nav pd">
                            <li className="mx-3 dark">
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li className="mx-3 dark">
                                <NavLink to="/about">About</NavLink>
                            </li>
                            <li className="mx-3 dark">
                                <NavLink to="/service">Services</NavLink>
                            </li>
                            <li className="mx-3 dark">
                                <NavLink to="/gallery">Gallery</NavLink>
                            </li>
                            <li className="mx-3 dark">
                                <NavLink to="/feedback">Feedback</NavLink>
                            </li>
                        </ul>

                        <div className="res-log-btn">
                            {user?.email || user?.phoneNumber ? (
                                <div>
                                    <NavLink
                                        onClick={handleLogout}
                                        className="main-btn nav-btn "
                                        to="/login"
                                    >
                                        <button>Logout</button>
                                    </NavLink>
                                    <NavLink to="/profile">
                                        <img
                                            style={{ borderRadius: "50%", marginLeft: "10px" }}
                                            width={40}
                                            height={40}
                                            alt="profile image"
                                            src={user.image_url || "https://img.freepik.com/free-icon/man_318-677829.jpg"}
                                        />
                                    </NavLink>
                                </div>
                            ) : (
                                <>
                                    <NavLink className="main-btn nav-btn mr-8" to="/login">
                                        <button>log in</button>
                                    </NavLink>
                                    <NavLink className="main-btn nav-btn " to="/signup">
                                        <button>Sign up</button>
                                    </NavLink>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;
