import React, { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import '../../Components/default.css';
import '../../Components/style.css';
import useAuth from '../../Hooks/useAuth';
import '../responsive.css';
import SelectCity from './SelectCity';
import SelectState from './SelectState';


const SignUp = () => {
    let navigate = useNavigate();

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [username, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [gender, setGender] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [dob, setDob] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const {
        signUpWithEmail,
        setUser,
        errorMessage,
        setErrorMessage,
        setIsLoading,
        isLoading,
        auth,
        updateProfile
    } = useAuth();


    const handleSignUp = (e) => {
        e.preventDefault();

        const user = {
            fname,
            lname,
            username,
            image: "",
            phoneNumber,
            zipCode,
            gender,
            state,
            city,
            dob,
            email,
            password
        };

        signUpWithEmail(user)
            .then((userCredential) => {
                const responseUser = userCredential.user;

                updateProfile(auth.currentUser, {
                    displayName: `${user.fname} ${user.lname}`
                }).then(() => {
                    setUser(user);
                    localStorage.setItem("user", JSON.stringify(user));
                    setErrorMessage("");
                }).catch((error) => {
                    setErrorMessage(error.message);
                });

                fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/user/`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(user)
                })
                    .then(res => res.json())
                    .then(data => {
                        if (data?.message === "Success") {
                            return navigate("/builder");
                        };
                    })
                    .catch((error) => {
                        setErrorMessage(error.message);
                    })
                    .finally(() => setIsLoading(false));
            })
            .catch((error) => {
                setErrorMessage(error.message);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div>
            <div className='ipad-res-logo'>
                <NavLink className="navbar-brand" to="/">
                    <img className='logo-img' src="https://i.ibb.co/VmWGkmz/Dark.png" alt="" />
                </NavLink>
            </div>
            <div className="container">
                <form id="signup-form" method="post" onSubmit={handleSignUp}>
                    <div className="signUp-form">
                        <div className="signUp-left">
                            <div className="signUp-img">
                                <a href="index.html">
                                    {" "}
                                    <img src="https://i.ibb.co/BLt1yd4/sign-up.png" alt="" />
                                </a>
                            </div>
                            <div className="signUp-logo container">
                                <NavLink to="/"><img className='logo-img' src="https://i.ibb.co/VmWGkmz/Dark.png" alt="" /></NavLink>
                            </div>
                        </div>
                        <div className="signUp-top">
                            {/* Error */}
                            <div
                                className={`alert alert-danger mx-auto text-center ${!errorMessage && "d-none"}`}
                                id="signup-error"
                            >
                                {errorMessage ? errorMessage : ""}
                            </div>
                            {/* contact-header */}
                            <div className="signUp-header">
                                <h2>Sign Up</h2>
                                <p>It's quick and easy </p>
                            </div>
                            {/* contact-input */}
                            <div>
                                <div className="signUp-input">
                                    <div className="mb-3">
                                        <input
                                            id="first-name"
                                            type="text"
                                            className="input-bg px-3"
                                            placeholder="First Name"
                                            required
                                            value={fname}
                                            onChange={(e) => setFname(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            id="last-name"
                                            type="text"
                                            className="input-bg px-3"
                                            placeholder="Last Name"
                                            required
                                            value={lname}
                                            onChange={(e) => setLname(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="signUp-input">
                                    <div className="mb-3">
                                        <input
                                            id="username"
                                            type="text"
                                            className="input-bg px-3"
                                            placeholder="Username"
                                            required
                                            value={username}
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            id="phone-number"
                                            type="text"
                                            className="input-bg px-3"
                                            placeholder="Phone Number"
                                            required
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="radio-btn">
                                    <p>Gender</p>
                                    <div className="form-check mx-2">
                                        <input
                                            defaultValue="male"
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            onChange={(e) => setGender(e.target.value)}
                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            Male
                                        </label>
                                    </div>
                                    <div className="form-check mx-2">
                                        <input
                                            defaultValue="female"
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault2"
                                            defaultChecked=""
                                            onChange={(e) => setGender(e.target.value)}
                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault2"
                                        >
                                            Female
                                        </label>
                                    </div>
                                    <div className="form-check mx-2">
                                        <input
                                            defaultValue="other"
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault2"
                                            defaultChecked=""
                                            onChange={(e) => setGender(e.target.value)}
                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault2"
                                        >
                                            Other
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div className="input-group mb-3">
                                        <SelectState setState={setState} />
                                    </div>

                                </div>
                                <div className="signUp-input">
                                    <div className="input-group mb-3">
                                        <div className="input-group mb-3">
                                            <SelectCity setCity={setCity} />
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input
                                            id="zip-code"
                                            type="text"
                                            className="input-bg px-3"
                                            placeholder="Zip"
                                            required
                                            onChange={(e) => setZipCode(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <input
                                        id="dob"
                                        type="date"
                                        className="input-bg px-3"
                                        placeholder="Date of birth"
                                        required
                                        value={dob}
                                        onChange={(e) => setDob(e.target.value)}
                                    />
                                </div>
                                <div className="signUp-input">
                                    <div className="mb-3">
                                        <input
                                            id="email"
                                            type="email"
                                            className="input-bg px-3"
                                            placeholder="Email Address"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <input
                                            id="pass"
                                            type="password"
                                            className="input-bg px-3"
                                            placeholder="Password"
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a href="">
                                    <button className="signUp-btn mb-2" type="submit">
                                        {isLoading ? "Loading..." : "Create account"}
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default SignUp;
