import React from 'react';
import { IoIosSend } from "react-icons/io";
import '../../Components/default.css';
import '../../Components/style.css';
import NavThree from '../Navbar/NavThree/NavThree';
import '../responsive.css';

const Contact = () => {
    return (
        <div>
            <NavThree />
            <section className="container">
                <div className="contact-form">
                    <div className="contact-top">
                        {/* contact-header */}
                        <div className="contact-header">
                            <h2>Contact us</h2>
                            <p>Reach out to us. We're here for you. </p>
                        </div>
                        {/* contact-input */}
                        <div>
                            <form action="#">
                                <div className="mb-4 ">
                                    <input type="text" className="input-bg px-3" placeholder="Name" />
                                </div>
                                <div className="mb-4">
                                    <input type="email" className="input-bg px-3" placeholder="Email" />
                                </div>
                                <div className="mb-4">
                                    <textarea
                                        placeholder="Enter Message"
                                        className="text-area-bg px-3 "
                                        rows={2}
                                        defaultValue={""}
                                    />
                                </div>
                                <a href="#">
                                    {" "}
                                    <button className="main-btn btn-send mb-2" type="submit">
                                        {" "}
                                        <IoIosSend className="send-icon" /> Send Message
                                    </button>
                                </a>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default Contact;