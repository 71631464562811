import React, { useState } from 'react';
import '../../Components/default.css';
import '../../Components/style.css';
import useAuth from '../../Hooks/useAuth';
import Navbar from '../Navbar/Navbar';
import '../responsive.css';

const Feedback = () => {
    const { user } = useAuth();

    const [rating, setRating] = useState("");
    const [textArea, setTextArea] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const handleFeedback = (e) => {
        e.preventDefault();
        setIsSuccess(false);
        setIsLoading(true);
        setError("");

        const toBase64 = file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };

        const tobase64Handler = async files => {
            const filePathsPromises = [];

            for (let i = 0; i < files.length; i++) {
                filePathsPromises.push(toBase64(files[i]));
            }

            const filePaths = await Promise.all(filePathsPromises);
            const mappedFiles = filePaths.map(base64File => base64File);
            return mappedFiles;
        };

        tobase64Handler(selectedFile)
            .then(data => {
                const feedback = JSON.stringify({
                    user: user.email || "",
                    rating: rating,
                    message: textArea,
                    attachments: data
                });

                fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/feedback/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: feedback,
                })
                    .then((res) => {
                        if (res) {
                            setRating("");
                            setTextArea("");
                            setSelectedFile(null);
                            setIsSuccess(true)
                            setError("");
                            setIsLoading(false);
                            setTimeout(() => {
                                setIsSuccess(false);
                            }, 5000)
                        };
                    })
            });
    };

    const activeUploadBtn = () => {
        document.getElementById("attachments").click();
    };

    return (
        <div>
            <Navbar></Navbar>
            <div className="feeddback container">
                {/* Success Alert */}
                <div
                    id="success-alert"
                    className={`alert alert-success mt-3 ${!isSuccess && "d-none"}`}
                >
                    Thank you for your feedback.
                </div>

                <div className="feedback-area">
                    <div className="feedback-left">
                        <h2>Feedback</h2>
                        <p>
                            Help us improve our product and services by telling us <br /> your
                            experience using <b>Birthship</b>.
                        </p>
                        <form method="post" id="feedback-form" onSubmit={handleFeedback}>
                            <div className="radio-btn-feed">
                                <div className="radio-top">
                                    <div className="form-check mr-3">
                                        <input
                                            defaultValue={1}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "1"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            1
                                        </label>
                                    </div>
                                    <div className="form-check mr-3">
                                        <input
                                            defaultValue={2}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "2"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            2
                                        </label>
                                    </div>
                                    <div className="form-check mr-3">
                                        <input
                                            defaultValue={3}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "3"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            3
                                        </label>
                                    </div>
                                    <div className="form-check mr-3">
                                        <input
                                            defaultValue={4}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "4"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            4
                                        </label>
                                    </div>
                                    <div className="form-check mr-3">
                                        <input
                                            defaultValue={5}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "5"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            5
                                        </label>
                                    </div>
                                </div>
                                <div className="radio-bottom">
                                    <div className="form-check mr-3">
                                        <input
                                            defaultValue={6}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "6"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            6
                                        </label>
                                    </div>
                                    <div className="form-check mr-3">
                                        <input
                                            defaultValue={7}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "7"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            7
                                        </label>
                                    </div>
                                    <div className="form-check mr-3">
                                        <input
                                            defaultValue={8}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "8"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            8
                                        </label>
                                    </div>
                                    <div className="form-check mr-3">
                                        <input
                                            defaultValue={9}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "9"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            9
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            defaultValue={10}
                                            className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            checked={rating === "10"}
                                            onChange={(e) => setRating(e.target.value)}

                                        />
                                        <label
                                            className="form-check-label font"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            10
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-area">
                                <textarea onChange={(e) => setTextArea(e.target.value)} name="" id="message" value={textArea} required />
                            </div>
                            <div className="attatch-text mt-4">
                                <button
                                    className="main-btn btn-border"
                                    id="attachment-btn"
                                    style={{ cursor: "pointer" }}
                                    onClick={activeUploadBtn}
                                    type="button"
                                >
                                    Attach Screenshot
                                </button>
                                <span id="file-count">{selectedFile?.length || 0} file selected</span>
                                <input
                                    type="file"
                                    id="attachments"
                                    multiple
                                    style={{ display: "none" }}
                                    onChange={(e) => setSelectedFile(e.target.files)}
                                />
                            </div>
                            <div className="feed-btn">
                                <button type="submit" className="main-btn btn-green">
                                    {isLoading ? "Loading..." : "Submit your feedback"}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="feedback-right">
                        <div className="feedback-right-box">
                            <p>0 - Not Satisfied in any way</p>
                            <p>1 - Not Very Satisfied</p>
                            <p className="last-p">10 - Very Satisfied</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feedback;