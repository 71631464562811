import { Navigate } from "react-router";

export default function PrivateRoute({ children }) {
  const jsonUser = localStorage.getItem("user");
  const user = JSON.parse(jsonUser);

  return user?.email || user?.phoneNumber ? (
    children
  ) : (
    <Navigate to="/signup" />
  );
}
