import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../responsive.css';
import '../../style.css';

const NavThree = () => {
    return (
        <div className='nav-green'>
            <nav className="navbar navbar-expand-lg container">
                <div className="container-fluid">
                    <NavLink className="navbar-brand" to="/">
                        <img className='logo-img' src="https://i.ibb.co/JF6h81q/Light-Logo.png" alt="" />
                    </NavLink>
                    <button
                        className="navbar-toggler collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="toggler-icon top-bar" />
                        <span className="toggler-icon middle-bar" />
                        <span className="toggler-icon bottom-bar" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 navbar-nav navThree">
                            <li className="mx-3 dark">
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li className="mx-3 dark">
                                <NavLink to="/pages">Pages</NavLink>
                            </li>
                            <li className="mx-3 dark">
                                <NavLink to="/blog">Blog</NavLink>
                            </li>
                            <li className="mx-3 dark">
                                <NavLink to="/contact">Contact</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default NavThree;