import { Button, Input, Modal, Spin, Typography } from "antd";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "../../Hooks/useAuth";

const PhoneNumber = ({ setInputPhoneNumber, onSignInSubmit, loading }) => {
  return (
    <div>
      <Typography.Text className="sign-in-verified">
        Log in with phone Number
      </Typography.Text>
      <Input
        className="input-bg"
        onChange={(e) => setInputPhoneNumber(e.target.value)}
        placeholder="Insert Phone Number with Country Code"
      />
      <Button
        className="nav-btn"
        onClick={async () => {
          onSignInSubmit();
        }}
      >
        {loading && <Spin />} Get Varified
      </Button>
    </div>
  );
};

const VerifyCode = ({
  setVerificationCode,
  handleVerificationCode,
  loading,
}) => {
  return (
    <div>
      <Typography.Text className="sign-in-verified">
        Enter Verification Code
      </Typography.Text>
      <Input
        className="input-bg"
        onChange={(e) => setVerificationCode(e.target.value)}
        placeholder="Enter Verification Code"
      />
      <Button
        className="nav-btn"
        onClick={() => {
          handleVerificationCode();
        }}
      >
        {loading && <Spin />} Submit
      </Button>
    </div>
  );
};

const PhoneSignIn = ({ isOpen, setVisible }) => {
  const navigate = useNavigate();
  const { auth, setUser } = useAuth();
  const [inputPhoneNumber, setInputPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerifiedScreen, setIsVerifiedScreen] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("ph==>", inputPhoneNumber);
  const handleOncancle = () => {
    setVisible(false);
  };

  const handleInputChange = (e) => {};

  const onChapchaVarify = () => {
    if (!window.recaptchaVerifier) {
      console.log("v==>", "varifing");
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            onSignInSubmit();
          },
        },
        auth
      );
    }
  };

  const onSignInSubmit = () => {
    setLoading(true);
    onChapchaVarify();
    const phoneNumber = inputPhoneNumber;
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        toast("Successfully Sent OTP", { delay: 5000 });
        window.confirmationResult = confirmationResult;
        setIsVerifiedScreen(true);
        setLoading(false);
      })
      .catch((error) => {
        toast(error.message, { delay: 5000 });
        setLoading(false);
      });
  };

  const handleVerificationCode = () => {
    setLoading(true);
    window.confirmationResult
      .confirm(verificationCode)
      .then((result) => {
        toast("Successfully Verified", { delay: 5000 });
        const user = result.user;
        setLoading(false);
        setUser(user);
        setIsVerifiedScreen(false);
        navigate("/builder");
        handleOncancle();
      })
      .catch((error) => {
        toast(error.message, { delay: 5000 });
        setLoading(false);
      });
  };

  return (
    <Modal open={isOpen} onCancel={handleOncancle} footer={null}>
      <div>
        <div id="sign-in-button"></div>
        {isVerifiedScreen ? (
          <VerifyCode
            setVerificationCode={setVerificationCode}
            handleVerificationCode={handleVerificationCode}
            loading={loading}
          />
        ) : (
          <PhoneNumber
            setInputPhoneNumber={setInputPhoneNumber}
            loading={loading}
            onSignInSubmit={onSignInSubmit}
          />
        )}
        <ToastContainer />
      </div>
    </Modal>
  );
};

export default PhoneSignIn;
