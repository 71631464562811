import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createContext, useEffect, useState } from "react";

import About from "./Components/About/About";
import Builder from "./Components/Builder/Builder";
import Campaigns from "./Components/Campaigns/Campaigns";
import Coming from "./Components/Coming/Coming";
import Contact from "./Components/Contact/Contact";
import Feedback from "./Components/Feedback/Feedback";
import Index from "./Components/Index/Index";
import PrivateRoute from "./Components/Router/PrivateRoute";
import PublicRoute from './Components/Router/PublicRoute';
import SignIn from "./Components/SignIn/SignIn";
import SignUp from "./Components/SignUp/SignUp";
import AuthProvider from './Context/AuthProvider';
import UserProfilePage from "./Components/Profile";

import BaseContext from "./Contexts/BaseContext";

import './App.css';

function App() {
    const [currentUser, setCurrentUser] = useState({})
    const user = JSON.parse(localStorage.getItem("user"));


    useEffect(() => {
        if (user)
            fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/user/${user.email}`).then((res) => res.json())
                .then((data) => {
                    const result = data.result

                    setCurrentUser(result)
                })
    }, [])
    return (

        <AuthProvider>
            <BaseContext.Provider value={{ currentUser: currentUser }} >
                <div className="">
                    <Router>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <PublicRoute>
                                        <Index />
                                    </PublicRoute>
                                }
                            />

                            <Route
                                path="/about"
                                element={
                                    <PublicRoute>
                                        <About />
                                    </PublicRoute>
                                }
                            />

                            <Route
                                path="/service"
                                element={
                                    <PublicRoute>
                                        <Coming />
                                    </PublicRoute>
                                }
                            />

                            <Route
                                path="/gallery"
                                element={
                                    <PublicRoute>
                                        <Coming />
                                    </PublicRoute>
                                }
                            />
                            <Route
                                path="/pages"
                                element={
                                    <PublicRoute>
                                        <Coming />
                                    </PublicRoute>
                                }
                            />
                            <Route
                                path="/blog"
                                element={
                                    <PublicRoute>
                                        <Coming />
                                    </PublicRoute>
                                }
                            />

                            <Route
                                path="/feedback"
                                element={
                                    <PublicRoute>
                                        <Feedback />
                                    </PublicRoute>
                                }
                            />

                            <Route
                                path="/login"
                                element={
                                    <PublicRoute>
                                        <SignIn />
                                    </PublicRoute>
                                }
                            />

                            <Route
                                path="/profile"
                                element={
                                    <PublicRoute>
                                        <UserProfilePage />
                                    </PublicRoute>
                                }
                            />

                            <Route
                                path="/signup"
                                element={
                                    <PublicRoute>
                                        <SignUp />
                                    </PublicRoute>
                                }
                            />

                            <Route
                                path="/builder"
                                element={
                                    <PrivateRoute>
                                        <Builder />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/campaigns"
                                element={
                                    <PrivateRoute>
                                        <Campaigns />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/contact"
                                element={
                                    <PublicRoute>
                                        <Contact />
                                    </PublicRoute>
                                }
                            />
                        </Routes>
                    </Router>
                </div>
            </BaseContext.Provider>
        </AuthProvider >

    );
}

export default App;
