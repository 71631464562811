import React, { useState, useEffect, useContext, createContext } from "react";
import {
    Row,
    Col,
    Avatar,
    Typography,
    Divider,
    Form,
    Input,
    Button,
    Select,
    DatePicker,
    Radio,
    Upload,
} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { NavLink } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

import BaseContext from "../../Contexts/BaseContext";

const { Title, Text } = Typography;

const UserProfilePage = () => {
    const {currentUser} = useContext(BaseContext);
    const [isEditing, setIsEditing] = useState(false);

    const onFinish = (values) => {
        console.log("Success:", values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const renderEditableSection = (label, valueKey, inputComponent) => (
        <Form.Item label={label}>
            {inputComponent}
        </Form.Item>
    );

    return (
        <div style={{ padding: "24px" }}>
          <div className="manu">
                <NavLink to="/">
                    <IoIosArrowBack className="fas fa-angle-left me-3 e-title" />
                    <span className="sale">Go Back</span>
                </NavLink>
            </div>
            <Row gutter={16} align="middle">
                <Col>
                    <img
                        style={{ borderRadius: "50%", marginLeft: "10px" }}
                        width={40}
                        height={40}
                        alt="profile image"
                        src="https://img.freepik.com/free-icon/man_318-677829.jpg"

                    />
                </Col>
                <Col>
                    <Title level={3}>{`${currentUser?.fname} ${currentUser?.lname}`}</Title>
                    <Text type="secondary">Birthship User</Text>
                </Col>
            </Row>
            <Divider />

            <Form
                name="basic"
                labelCol={{
                    span: 8
                }}
                wrapperCol={{
                    span: 16
                }}
                style={{
                    maxWidth: 600
                }}
                initialValues={{
                    remember: true
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="horizontal"
            >
                {renderEditableSection(
                    "First Name",
                    "fname",
                    <Input placeholder="Enter first name" value={currentUser?.fname} />
                )}

                {renderEditableSection(
                    "Last Name",
                    "lname",
                    <Input placeholder="Enter last name" value={currentUser?.lname} />
                )}

                {renderEditableSection(
                    "Username",
                    "username",
                    <Input placeholder="Enter username" value={currentUser?.userName} />
                )}


                <Form.Item label="Image" valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload action="/upload.do" listType="picture-card">
                        <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    </Upload>
                </Form.Item>

                {renderEditableSection(
                    "Phone Number",
                    "phoneNumber",
                    <Input placeholder="Enter phone number" value={currentUser?.phoneNumber} />
                )}

                {renderEditableSection(
                    "Address",
                    "address",
                    <Input placeholder="Enter address" value={currentUser?.email} />
                )}

                {renderEditableSection(
                    "Gender",
                    "gender",
                    <Radio.Group value={currentUser?.gender}>
                        <Radio value="male">Male</Radio>
                        <Radio value="female">Female</Radio>
                        <Radio value="other">Other</Radio>
                    </Radio.Group>
                )}

                {renderEditableSection(
                    "State",
                    "state",
                    <Input placeholder="Enter state" value={currentUser?.state} />
                )}

                {renderEditableSection(
                    "City",
                    "city",
                    <Input placeholder="Enter city" value={currentUser?.city} />
                )}

                {renderEditableSection(
                    "Date of Birth",
                    "dob",
                    <DatePicker placeholder="Select date of birth" />
                )}

                {renderEditableSection(
                    "Zip Code",
                    "zipCode",
                    <Input placeholder="Enter zip code" type="number" value={currentUser?.zipCode} />
                )}

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default UserProfilePage;
