import React from 'react';
import '../../Components/default.css';
import '../../Components/style.css';
import NavThree from '../Navbar/NavThree/NavThree';
import '../responsive.css';

const Coming = () => {
    return (
        <>
            <NavThree></NavThree>
            <div className="home-banner-area">
                <div className="container">
                    <div className="coming">
                        <h1>
                            <i>Coming Soon...</i>
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Coming;